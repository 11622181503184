import { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from "@mui/x-license-pro";

const container = document.querySelector("#root");
if (container == null) {
  console.error("Unable to find a root element.");
} else {
  console.debug("Injecting React into page.");

  // This is considered public information by MUI and doesn't need to be treated as any kind of secret.
  LicenseInfo.setLicenseKey(
    "e863de40350ce5432bb10ccf571d26dcTz03NzI0NixFPTE3Mjk3MjU2MzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  );

  try {
    const Application = lazy(async () => import("./Application.js"));
    const loadTime = new Date();

    const root = createRoot(container);
    root.render(
      <Suspense
        fallback={
          <div className="simple-message">
            <p>Loading...</p>
          </div>
        }
      >
        <Application loadTime={loadTime} />
      </Suspense>,
    );
  } catch (e: unknown) {
    container.innerHTML = `<div class="simple-message"><p>Unable to load application.</p><pre>${e}</pre></div>`;
  }
}
